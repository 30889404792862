.ant-tabs-tab:hover {
    color: #171a20 !important;
}

.modal-tabs {
    .ant-tabs-nav {
        margin-top: 0 !important;
    }

    .ant-tabs-tab {
        border-bottom: white !important;
        border-left: white !important;
        border-right: white !important;

        .ant-tabs-tab-active {
            border-bottom: 1px solid #f0f0f0 !important;
            border-left: 1px solid #f0f0f0 !important;
            border-right: 1px solid #f0f0f0 !important;

            .ant-tabs-tab-btn {
                color: #171a20 !important;
            }
        }
    }

    .ant-tabs-content-holder {
        padding: 1em;
        border-top: 1px solid #f0f0f0;
        border-left: 1px solid #f0f0f0;
        border-right: 1px solid #f0f0f0;
        min-height: 500px;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        border-bottom: 1px solid #f0f0f0 !important;
        border-left: 1px solid #f0f0f0 !important;
        border-right: 1px solid #f0f0f0 !important;
    }
}

.compare-tabs {
    .ant-tabs-tab {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-bottom: 10px;
    }
}

.ant-image {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
    height: 245px;

    img {
        flex-shrink: 0 !important;
        min-width: 100% !important;
        min-height: 100% !important;
    }
}

.ant-layout-footer {
    padding: 0;
    bottom: 0;
    width: 100%;
    text-align: left;
    background-color: #ffffff !important;
    padding: 20px;

    span {
        color: #000000 !important;
    }
}

.table-columns {
    font-weight: bold;
    white-space: nowrap;
}
.modal-specification-content {
    width: 100%;
    display: flex;

    .table-data {
        width: -webkit-fill-available;
    }
}

.menu-sitename {
    a {
        color: #000000 !important;
    }
}

@media screen and (max-width: 700px) {
    .compare-tabs .ant-tabs-tab {
        padding-right: 1em !important;
        padding-left: 1em !important;
    }
}
