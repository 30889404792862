.facility-react-modal {
    .close-button {
        text-align: center;
        line-height: 32px;
        align-self: flex-end;
        margin: 0.5em;
        font-size: 1.3rem;
        height: 32px;
        width: 32px;
        cursor: pointer;
        transition: opacity 0.15s ease-in-out;

        &:hover {
            opacity: 0.6;
        }
    }

    .slider-button {
        background-color: transparent;
        outline: none;
        border: none;
        width: 2em;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 5px;
        cursor: pointer;
        transition: background-color 0.15s ease-in-out;
        z-index: 1;

        &:hover {
            background-color: rgba(black, 0.2);
        }

        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }

        &.last {
            display: none;
        }

        .button {
            margin: 0 5px;
            display: inline-block;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;

            &.right {
                border-left: 8px solid black;
            }

            &.left {
                border-right: 8px solid black;
            }
        }
    }
}
