.checkboxButton-container {
    background-color: white;
    border-radius: var(--borderrad);
    margin-bottom: 5px;
    padding: 5px;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    transition: box-shadow 0.1s ease-in-out;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0 2px 13px 0px;
    }

    .checkboxButton-items {
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .checkbox-btn-box {
            display: -webkit-flex;
            display: flex;
            justify-content: flex-end;
            height: 20px !important;
        }
    }
}
