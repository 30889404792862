.mom-parameter-box-container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
}

.mom-parameter-box {
    display: block;
    margin: 0 20px 10px 20px !important;
    height: 100% !important;

    .icon {
        display: block;
        width: 50px;
        height: 50px;
        margin: 0 auto 16px auto;
    }

    .value {
        display: block;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }
}
@media screen and (max-width: 1024px) {
    .mom-parameter-box {
        width: 100px;
        height: 100px;
        margin: 4px;
        padding: 8px;

        .icon {
            width: 30px;
            height: 30px;
            margin: 0 auto 8px auto;
        }

        .value {
            font-size: 16px;
            font-weight: normal;
        }
    }
}
