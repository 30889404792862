table {
    border-collapse: collapse;
    width: 100%;
    margin: auto;

    .spectable-image-header {
        padding-bottom: 1.2rem;
        vertical-align: top;
        text-align: center;

        .spectable-header-div {
            width: 150px;
            margin: auto;

            .spectable-image {
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: var(--borderrad);
            }
            span {
                margin-top: 0.8rem;
                max-width: 150px;
                display: block;
                text-align: center;
            }
        }
    }

    td,
    th {
        padding: 6px 10px;
    }

    .spectable-header {
        padding-top: 1.3rem;
        font-size: 1.3rem;
    }

    .spectable-prop-row {
        border-bottom: 1px rgba(0, 0, 0, 0.2);
        border-bottom-style: solid;
    }

    .spectable-prop-header {
        font-size: 1rem;
        max-width: 150px;
    }

    .spectable-prop {
        text-align: center;
    }
}
