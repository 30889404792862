Table {
    border-collapse: collapse;
    margin: auto;
    background-color: white;
    color: black;
    margin-bottom: 20px !important;
}

.table-container {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .CloseModalButton{
    text-align: center;
    line-height: 32px;
    font-size: 1.4rem;
    height: 32px;
    width: 32px;
    background: transparent;
    cursor: pointer;
    transition: opacity 0.15s ease-in-out;
    margin-left: auto;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.6;
    }
}

.linkToTable{
    text-decoration: underline;
    font-size: large;
    cursor: pointer;
    margin-top: -20px;
}
.backgroundToHide{
position: absolute;
width: 100vw;
height: 100vh;
z-index: 1;
}

.headerTable{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.headerText{
    font-size: x-large;
}