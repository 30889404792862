.checkboxlist {
    display: block;
    width: 100%;
    overflow-y: hidden;
    position: relative;

    --text-height: 32px;

    .checkboxlist-items-hidden {
        max-height: calc(120px + var(--text-height));
    }

    .checkboxlist-showMore {
        height: var(--text-height);
        line-height: var(--text-height);
        user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        position: absolute;
        width: 100%;
        bottom: 0;
        color: black;
        background: linear-gradient(transparent, rgba(255, 255, 255, 0.9) 40%, white 70%);
        background: -webkit-linear-gradient(transparent, rgba(255, 255, 255, 0.9) 40%, white 70%);
        background: -o-linear-gradient(transparent, rgba(255, 255, 255, 0.9) 40%, white 70%);
        background: -moz-linear-gradient(transparent, rgba(255, 255, 255, 0.9) 40%, white 70%);
        text-align: center;
        cursor: pointer;
    }
}
