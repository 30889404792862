:root {
    --borderrad: 10px;
}

html,
body {
    height: auto !important;
}

body {
    margin: 0;
    font-family: "Roboto" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-attachment: fixed;
    background-image: url("./assets/images/enkoping_husb_bifiview_img_2045.jpg");
    height: 1329px;
    background-repeat: repeat;
    background-position: top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

}


p {
    font-size: 18px;
}

.wordWrap {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

footer {
    width: 100%;
    background-color: white;
    color: black;
}

footer .footer-page {
    padding: 20px;
    margin: auto;
    max-width: calc(1024px + 40px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

footer .footer-page .footer-col {
    box-sizing: border-box;
    margin: 10px 25px;
    width: 150px;
    display: flex;
    flex-direction: column;
}

.ant-layout,
.ant-layout-footer,
.ant-layout-sider,
.ant-menu {
    color: #fff !important;
    background: none !important;
}
.ant-layout-header {
    margin-bottom: 2em !important;
    background-color: #ffffff !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 13px 0px;
}
.ant-menu-horizontal {
    border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-item {
    border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-item a::before {
    bottom: 0 !important;
    z-index: 1;
}
.ant-pagination-disabled{
    visibility: hidden !important;
    height: 0px;
    margin-bottom: 0 !important;
}

.home-text{
    background-color: rgba(99, 99, 99, 0.5);
    padding: 5px 10px 0px 10px;
    border-radius: 10px;
    width: fit-content;
    height: fit-content;
}

.white-color {
    color: #fff;
}
.black-color {
    color: #171a20;
}
.grey-color {
    color: #5c5e62;
}

.bg-white {
    background-color: #fff;
}
.height-100 {
    height: 100% !important;
}
.width-100 {
    width: 100%;
}
.p-1 {
    padding: 1em;
}
.mb-0 {
    margin-bottom: 0;
}

.h1 {
    font-weight: 500;
    font-size: 42px;
    line-height: 49px;
}

.h2 {
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
}

.h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 1em;
}

.h4 {
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
}

.h5 {
    font-weight: 600;
    font-size: 14px;
}

.button-secondary {
    margin-top: 10px;
    border-width: 2px !important;
    border-color: #171a20 !important;
    color: #171a20 !important;
}

.button-primary {
    margin-top: 10px;
    background-color: #3e6ae1 !important;
    color: #fff !important;
    border: none !important;
}
.button-primary:disabled,
.button-secondary:disabled,
.button-primary[disabled],
.button-secondary[disabled] {
    opacity: 0.4;
}

.button-secondary:active,
.button-secondary:focus,
.button-secondary:focus:active,
.button-primary:active,
.button-primary:focus,
.button-primary:focus:active {
    background-image: none !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.button-primary:hover {
    background: #40a9ff !important;
}
.button-primary:disabled:hover,
.button-primary[disabled]:hover {
    background-color: #3e6ae1 !important;
    color: #fff !important;
}

.button-secondary:hover {
    border-color: #3e6ae1 !important;
    color: #3e6ae1 !important;
    transform: scale(1.01);
}
.button-secondary:disabled:hover,
.button-secondary[disabled]:hover {
    transform: scale(1);
    border-color: #171a20 !important;
    color: #171a20 !important;
}

.ant-modal-header,
.ant-modal-content {
    border-radius: var(--borderrad) !important;
    border-bottom: none !important;
}

.ant-menu-submenu-popup > .ant-menu {
    background-color: #fff !important;
}

.ant-table {
    font-size: 16px !important;
    max-width: 91vw;
    width: fit-content;
    border-radius: var(--borderrad) !important;
}

.ant-table-footer{
    background-color: white !important;
}
.ant-table-column-sorters{
    pointer-events: none;
}
.ant-table-thead > tr > th {
    font-weight: 600 !important;
    background: #f0f0f0 !important;
}

.start-page-card {
    border-radius: var(--borderrad);
    border: 1px solid #d3d3d3;
}

.key-col {
    flex-basis: 33%;
    flex-grow: 1;
    position: relative;
    width: 300px;
}

.key-col:last-of-type:after {
    visibility: hidden;
}

.key-col:after {
    content: "";
    background: #171a20;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    height: 50%;
    width: 1px;
    margin: auto;
}

.checked {
    border: 2px solid #1890ff !important;
}

.ant-collapse-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.ant-collapse-arrow {
    padding: 0 !important;
    top: 0 !important;
}

.compare-facilities-specification-col .key-value-col div:after {
    content: "";
    background: #171a20;
    position: absolute;
    top: 5%;
    bottom: 5%;
    left: 100%;
    width: 1px;
}

.panel-style {
    --offset: 1rem;
    background-color: #fff;
    padding: var(--offset);
    height: calc(100% - var(--offset));
    margin-bottom: var(--offset);
    border-radius: var(--borderrad) !important;
    transition: box-shadow 0.1s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 13px 0px;
    border: 2px solid transparent;
}
.panel-style:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 13px 4px !important;
    cursor: pointer !important;
}

.html-component a {
    color: #fff;
    text-decoration: underline;
}

.html-component a.black-link {
    color: #1890ff;
    text-decoration: underline;
}

.html-component a.white-link {
    color: white;
    text-decoration: underline;
}

.html-component a.white-link:hover {
    color: rgba(0, 0, 0, 0.85);
}

.collapsed-menu {
    display: none !important;
}
.open-menu {
    background-color: #fff !important;
    position: relative;
    z-index: 1000;
}
.mobile-menu-btn {
    margin: 16px !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: black !important;
}

.facility-card .facility-card-img,
.facility-card-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--borderrad);
    color: black;
}

.about-facility-card-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--borderrad);
    color: black;
}

.ant-tabs-tab-active {
    transition: background-color 0.5s;
    background-color: #40a9ff !important;
    border-radius: var(--borderrad);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}

.ant-tabs-ink-bar {
    display: none;
}

@media screen and (min-width: 700px) {
    .panel-card,
    .facility-card {
        min-height: 13rem;
    }
}

@media screen and (max-width: 1050px) {
    .about-div {
        padding: 0 0.4em;
    }
}

@media screen and (max-width: 1024px) {
    .ant-col-16 {
        flex: 100% !important;
        max-width: 90% !important;
    }
    .ant-col-14 {
        flex: 100% !important;
        max-width: 100% !important;
    }
    .menu-sitename {
        padding-left: 1em !important;
    }
    .ant-layout-header {
        padding: 0 !important;
    }
    .modal-body-content .modal-carousel-images,
    .modal-body-content .modal-body-text {
        max-width: 100% !important;
    }
    .ant-table  {
        overflow-x: scroll;
    }

}

@media screen and (max-width: 700px) {
    body {
        background-size: 2500px !important;
        background-position: top !important;
    }
    .ant-col-8.compare-card,
    .ant-col-8.start-column {
        flex: 100% !important;
        max-width: 100% !important;
    }
    .ant-col-8.start-column {
        margin-bottom: 1rem;
    }

    .start-column .h2 {
        height: auto !important;
    }
    .modal-body-content {
        padding: 0 !important;
    }
    .key-col:after {
         visibility: hidden;
    }
    .ant-col {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
        padding-left: 1em !important;
        padding-right: 1em !important;
    }
    .ant-col .ant-col-12 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .compare-facilities-specification-row {
        overflow: visible !important;
        max-height: 100% !important;
        margin-left: 0 !important;
    }
    .compare-facilities-specification-col .ant-row {
        margin: 0 !important;
    }
    .compare-facilities-specification-col .ant-col {
        padding-left: 0 !important;
    }
    .compare-facilities-specification-col .key-value-col div:after {
        background: white !important;
    }
    .compare-tabs .ant-tabs-tab {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .checkbox-btn-box {
        width: 20% !important;
        padding: 0 !important;
        flex: 0 0 20% !important;
        max-width: 20% !important;
    }
    .checkbox-btn-label {
        width: 80% !important;
        padding: 0 !important;
        flex: 0 0 80% !important;
        max-width: 80% !important;
    }
}
